import { useEffect, useMemo, useState } from 'react';
import { googleLogout } from '@react-oauth/google';
import Login from './components/Login';
import axios from 'axios';
import OrderFulfillment from './components/OrderFulfillment';
import { withALErrorBoundary } from './helpers/ALErrorBoundary';
import './App.css';
import { getCookie } from './hooks/helpers';
import { useALError } from './helpers/ALErrorBoundary';
// import { getCookie } from './hooks/helpers';

function App() {
  // const u = getCookie("username");
  const [user, setUser] = useState(null);
  const [profile, setProfile] = useState(null);
  const [notificationPermissions, setNotificationPermissions] = useState(null);

  const { sendReport } = useALError();

  // log out function to log the user out of google and set the profile array to null
  const logOut = async () => {
    await axios.post("/api/event", {
      "type": "LOGOUT",
      "user": profile.email,
      "sample": {}
    });
    googleLogout();
    setProfile(null);
    setUser([]);

  };

  useEffect(() => {
    async function askNotificationPermission() {
      // Check if the browser supports notifications
      if (!("Notification" in window)) {
        console.log("This browser does not support notifications.");
        return;
      }
      Notification.requestPermission().then((permission) => {
        // set the button to shown or hidden, depending on what the user answers
        setNotificationPermissions(permission);
      });
    }
    askNotificationPermission();
  }, []);

  return (
    <div className="app">
      {(profile?.email && profile?.email.includes("analuisa.com")) ? (
        <>
          <div className={`browser_notification browser_notification_${notificationPermissions}`}>
            &nbsp;
            <div className="browser_notification_detail">
              <p>Notifications are <b>{notificationPermissions}</b>.</p>
              {(notificationPermissions === "granted") ? (
                <i>If you're not getting notifications, take a look at your computer settings.</i>
              ) : (
                <i>Please adjust your Google Chrome settings to enable notifications.</i>
              )}
            </div>
          </div>
          <button className="logout" onClick={() => logOut()}>
            <img className="logout_image" alt="User Profile" src={profile.picture} />
            Logout ({profile.firstName} {profile.lastName[0]}.)
          </button>
          <OrderFulfillment name={profile.firstName} profile={profile} />
        </>
      ) : (
        <Login
          setProfile={setProfile}
          setUser={setUser}
          user={user ? user : {"access_token": getCookie("access_token")}}
        />
      )}
    </div>
  );
}

export default withALErrorBoundary({
  name: "App",
  priority: "P1",
})(App);

