import "./column.css";

function Column({ width, children }) {
  return (
    <div className={`column`} style={{width: `${width}%`}}>
      {children}
    </div>
  )

}

export default Column;