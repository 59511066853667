import { useEffect, useState } from "react";
import { format } from "date-fns";
import axios from "axios";

import "./orderCard.css";

function OrderCard({ email, order, handleNextStatus, handlePickedItem, handleCancelFulfillment = () => {} }) {
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    // Enable / Disable the "DONE" button (to fulfil orders)
    const itemsArePicked = order.lineItems.every(i => i.picked === true);
    if (itemsArePicked !== isReady) {
      setIsReady(itemsArePicked);
    }
  }, [isReady, order]);

  if (!order) return null;

  function sortByTypeNameSize(a, b) {
    const has10KGoldA = a.product?.tags && a.product?.tags?.includes("Components:10K Solid Gold");
    const has10KGoldB = b.product?.tags && b.product?.tags?.includes("Components:10K Solid Gold");

    if (has10KGoldA && !has10KGoldB) {
        return -1; // A comes before B if A has 10K Solid Gold
    } else if (!has10KGoldA && has10KGoldB) {
        return 1; // B comes before A if B has 10K Solid Gold
    }

    // Define the order of product types
    const typeOrder = {
      "Earrings": 1,
      "Necklaces": 2,
      "Rings": 3,
      "Bracelets": 4,
    };
    const typeA = typeOrder[a.product?.productType || ""] || Infinity; // Assign a default order for types not in the defined order
    const typeB = typeOrder[b.product?.productType || ""] || Infinity;

    // First, compare based on product type
    if (typeA !== typeB) {
        return typeA - typeB;
    } else {
        // If types are equal, compare based on the first part of the name
        const nameA = a.name.split(" - ")[1] || a.name;
        const nameB = b.name.split(" - ")[1] || b.name;
        const nameComparison = nameA.localeCompare(nameB);

        // If names are equal or product types are not Rings or Earrings, compare based on selected option value
        if (nameComparison === 0 || typeA === 0) {
          const valueA = (a.variant.selectedOptions[0] && a.variant.selectedOptions[0].value) || "";
          const valueB = (b.variant.selectedOptions[0] && b.variant.selectedOptions[0].value) || "";
          return valueA.localeCompare(valueB);
        } else {
          return nameComparison;
        }
    }
  }

  return (
    <div className={`order_card order_card--${order.status}`}>
      <div className="order_card_header">
        <a
          className="order_card_header_name"
          target="_blank"
          href={`https://admin.shopify.com/store/analuisaparis/orders/${order.id.replace("gid://shopify/Order/", "")}`}
        >{order.name}</a>
        <span className="order_card_header_finance">{order.displayFinancialStatus}</span>
      </div>
      <p className="order_card_date">{format(new Date(order?.createdAt), "MMM d, yyyy 'at' h:mma")}</p>

      <div className="order_card_subheader">
        <p className="order_card_customer">{order.firstName} {order.lastName}<br />{order.email}</p>
        <p className="order_card_total">
          # of products:&nbsp;
          <span className="order_card_total_qty">{order.lineItems.reduce((red, i) => red + (i?.quantity || 0), 0)}</span>
        </p>
      </div>

      {order.status === "PICK" && (
        <div className="order_card_items">
          {order.lineItems.sort(sortByTypeNameSize).map((item, i) => {
            const { image, name, quantity, sku, picked, variant, product } = item;
            const names = name.split("-");
            return (
              <div
                className={`order_card_items_item${picked ? ` order_card_items_item--picked` : ""}`}
                key={`order_${order.name}_${i}`}
                onClick={() => handlePickedItem(order.status, order.id, sku, email)}
              >
                {product && (
                  <div className="items_item_tags">
                    {product?.productType !== "" && (
                      <p className="items_item_tag items_item_type">{product?.productType || ""}</p>
                    )}
                    {product?.tags?.includes("Components:Solid Gold") && (
                      <p className="items_item_tag items_item_solidgold">Solid Gold</p>
                    )}
                  </div>
                )}
                <p className="items_item_details">
                  <span className="items_item_details_qty">{quantity}x</span>
                  <span className="items_item_details_name" data-name={name}>
                    {
                      (
                        (names[0].includes("Silver") && !names[1].includes("Silver")) ||
                        (names[0].includes("Gold") && !names[1].includes("Gold"))
                      ) ? (`(${names[0].trim()}) ${names[1]}`) : (names.length === 1 ? names[0] : names[1])
                    }
                    {variant && variant?.selectedOptions[0].value !== "Default Title" && (
                      <span>
                        - <span className="items_item_details_name_size_name">{variant.selectedOptions[0].name}:</span>
                        <span className="items_item_details_name_size_value">{variant.selectedOptions[0].value}</span>
                      </span>
                    )}
                    {/* <span className="items_item_details_name_sub">({names[0].trim()})</span> */}
                  </span>
                </p>
                <p className="items_item_sku">SKU: {sku}</p>
                <div className="items_item_done">{picked && "✅"}</div>
              </div>
            )
          })}
        </div>
      )}

      <p className="order_card_note">Note: {order.note}</p>

      <div className="order_card_actions">
        {(order.status === "FULFILL") ? (
          <button className="order_card_actions_btn order_card_actions_btn--unfulfill" onClick={() => handleCancelFulfillment(order.id)}>UNFULFILL</button>
        ) : (
          <>
            {(order.status === "PICK") ? (
              <button
                className="order_card_actions_btn order_card_actions_btn--fulfill"
                onClick={() => handleNextStatus("FULFILL", order.id)}
                disabled={!isReady}
              >
                DONE
              </button>
            ) : (
              <button className="order_card_actions_btn order_card_actions_btn--next" onClick={() => handleNextStatus("PICK", order.id)}>NEXT</button>
            )}
            {order.status !== "" && (
              <button className="order_card_actions_btn order_card_actions_btn--previous" onClick={() => handleNextStatus("", order.id)}>PREVIOUS</button>
            )}
          </>
        )}
      </div>

      <p className="order_card_stylist">
        {order?.transactions?.user && `Stylist: ${order?.transactions?.user?.firstName} ${order?.transactions?.user?.lastName}`}
      </p>
      {order.status === "PICK" && <p className="order_card_packer">Packer: {order.agent}</p>}
    </div>
  )

}

export default OrderCard;