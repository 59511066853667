import { useState, useEffect } from "react";
import axios from "axios";
import { useGoogleLogin } from '@react-oauth/google';
import { createCookie } from "../hooks/helpers";
import "./login.css";

function Login({ setProfile, setUser, user }) {
  const [formData, updateFormData] = useState(null);

  function handleChangeCustomSku(target) {
    const { type, value, name } = target;
    let data = {
      ...formData,
    };

    if (type === "checkbox") {
      data = {
        ...data,
        [name]: !formData[name],
      };
    } else {
      data = {
        ...formData,
        [name]: value,
      };
    }
    updateFormData(data);
  }

  const login = useGoogleLogin({
      onSuccess: (codeResponse) => {
        console.log(codeResponse, " => useGoogleLogin: codeResponse");
        setUser(codeResponse);
      },
      onError: (error) => console.log('Login Failed:', error)
  });

  useEffect(
    () => {
      if (user?.access_token) {
        axios
          .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`, {
            headers: {
              Authorization: `Bearer ${user.access_token}`,
              Accept: 'application/json'
            }
          })
          .then(async (res) => {
            console.log(res.data, " => data???");
            const d = await axios.post("/api/auth", res.data);
            console.log(d.data.data, " => DATA FROM DB");
            await axios.post("/api/event", {
              "type": "LOGIN",
              "user": res.data.email,
              "sample": {}
            });
            setProfile(d.data.data);
            createCookie("access_token", user.access_token, 365);
          })
          .catch((err) => console.log(err));
      }
    },
    [setProfile, user]
  );


  return (
    <button className="login" onClick={login}>
      <img src="https://upload.wikimedia.org/wikipedia/commons/c/c1/Google_%22G%22_logo.svg" alt="Google logo" />
      <span>Sign in with Google</span>
    </button>
  )
}

export default Login;