import { useEffect, useState } from "react";
import axios from "axios";
import io from 'socket.io-client';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


import Column from "./Column";
import Title from "./Title";
import OrderCard from "./OrderCard";
import "./orderFulfillment.css";
import useAudio from "../hooks/useAudio";


let socket = io("/");

function sortByCreatedAt(object) {
  const sortedArray = Object.entries(object).sort((a, b) => {
      const dateA = new Date(a[1].createdAt);
      const dateB = new Date(b[1].createdAt);
      return dateA - dateB;
  });

  const sortedObject = {};
  sortedArray.forEach(([key, value]) => {
      sortedObject[key] = value;
  });

  return sortedObject;
}

const STATUS_STEPS = {
  "": {
    "prev": "IN_PROGRESS",
    "next": "NEW"
  },
  "PICK": {
    "prev": "NEW",
    "next": "IN_PROGRESS"
  },
  "FULFILL": {
    "prev": "IN_PROGRESS",
    "next": "FULFILLED"
  }
}

function OrderFulfillment({ name, profile }) {
  const [orders, setOrders] = useState(null);
  const [viewAllOrders, setViewAllOrders] = useState(false);
  const [countdown, setCountdown] = useState(10);
  const [playing, toggleSound] = useAudio("./WAV_Chord.wav");

  useEffect(() => {
    socket.on('connect', () => {
      console.log('Connected to server');
      toast(`Connected from server...`, {type: "info"});
      fetchOrders();
    });

    socket.on('disconnect', () => {
      console.log('Disconnected from server');
      toast(`Disconnected from server...`, {type: "error"});
      // Additional logic for handling disconnection
    });

    socket.removeAllListeners("NEW_ORDER");
    socket.on("NEW_ORDER", (data) => {
      console.log("UPDATED_ORDER event....");
      const order = JSON.parse(data);
      console.log(order);
      try {
        const _orders = { ...orders };
        const newOrders = {
          ..._orders,
          [order.id]: {
            ...order,
          }
        };
        console.log(newOrders, " => ALL ORDERS 1");
        setOrders(sortByCreatedAt(newOrders));
        toggleSound();

        // Send browser Notification
        new Notification(`New Order: ${order.name}`, {
            body: `${order?.firstName || "Customer"} ordered ${order.lineItems.reduce((red, i) => red += i.quantity, 0)} item(s)`,
            icon: "/logo192.png",
            vibrate: [200, 100, 200],
        });
      } catch (error) {
        console.log("ERROR...");
        console.log(error);
      }
    });

    socket.removeAllListeners("UPDATED_ORDER");
    socket.on("UPDATED_ORDER", (data) => {
      console.log("UPDATED_ORDER event....");
      const order = JSON.parse(data);
      try {
        const _orders = { ...orders };
        const newOrders = {
          ..._orders,
          [order.id]: {
            ...order,
          }
        };
        setOrders(sortByCreatedAt(newOrders));
      } catch (error) {
        console.log("ERROR...");
        console.log(error);
      }
    });
  }, [orders]);




  async function fetchOrders() {
    return axios.request({
      method: "GET",
      url: "/api/redis/orders"
    }).then((r) => {
      if (r.status === 200) {
        setOrders(sortByCreatedAt(r.data.orders));
        return "ok";
      }
      toast(`Code: FE004`, {type: "error"});
    }).catch((error) => {
      toast(`Code: FE005`, {type: "error"});
      return error;
    });
  }
  // TODO:
  // On retreive orders (every X secs?)
  // Create socket event to send new orders!!
  useEffect(() => {
    fetchOrders();
  }, []);


  async function handleNextStatus(status, orderId) {
    console.log(`handleNextStatus: STATUS: __${status}__`);
    let _orders = {...orders};
    let _order = {..._orders[orderId]};
    let fulfillmentId = "";
    if (status === "FULFILL") {
      // Get latest fulfillmentOrders
      const latestFulfillmentOrder = _order.fulfillmentOrders[_order.fulfillmentOrders.length - 1].id;
      const fulfillmentOrderId = latestFulfillmentOrder.replace("gid://shopify/FulfillmentOrder/", "");
      const r = await axios.request({
        method: "POST",
        url: `/api/order/fulfill/${fulfillmentOrderId}`
      }).then((r) => {
        if (r.status === 200) {
          const { fulfillmentCreateV2 } = r.data.data;
          if (fulfillmentCreateV2.userErrors.length > 0) {
            // TODO: handle error
            const message = fulfillmentCreateV2.userErrors[0].message;
            if (message.includes("status= closed")) {
              return "ok";
            }
            toast(`Code: FE006 - message: ${message}`, {type: "error"});
            return "error";
          }
          if (fulfillmentCreateV2?.fulfillment?.status === "SUCCESS") {
            fulfillmentId = fulfillmentCreateV2.fulfillment.id;
            // Set status to "DONE"
            return "ok";
          }
        }
      }).catch((error) => {
        console.log(error);
        toast(`Code: FE005`, {type: "error"});
      });

      // early return in case of error
      if (r === "error") {
        return;
      }
    } else {
      if (status === "") {
        // TODO: Reset items "picked" status
        _order["lineItems"].forEach(i => {
          i.picked = false;
        });
      }
    }

    _orders = {
      ..._orders,
      [orderId]: {
        ..._order,
        status: status,
        agent: profile.email,
        fulfillmentId: fulfillmentId,
      },
    };



    axios.post(`/api/event/emit`, {
      event: "UPDATED_ORDER",
      data: JSON.stringify(_orders[orderId])
    }).then(async (r) => {
      await axios.post("/api/event", {
        "type": STATUS_STEPS[status]["next"],
        "user": profile.email,
        "sample": {
          "from": STATUS_STEPS[status]["prev"],
          "order_id": orderId,
        }
      });
    });
  }

  function handlePickedItem(status, orderId, sku, email) {
    let _orders = {...orders};
    let _order = {..._orders[orderId]};
    if (_order.agent !== email) {
      return;
    }
    if (status === "PICK") {
      let idxLineItem = _order["lineItems"].findIndex(i => i.sku === sku);
      _order["lineItems"][idxLineItem]["picked"] = !_order["lineItems"][idxLineItem]["picked"];
    }

    axios.post(`/api/event/emit`, {
      event: "UPDATED_ORDER",
      data: JSON.stringify(_order)
    });
  }

  async function handleCancelFulfillment(orderId) {
    let _orders = {...orders};
    let _order = {..._orders[orderId]};
    console.log(_order, " => _order");
    let fulfillmentId = "";
    if (_order.fulfillmentId) {
      fulfillmentId = _order.fulfillmentId.replace("gid://shopify/Fulfillment/", "");
    } else {
      // fulfillmentId is not available in the order object
      const orderData = await axios.request({
        method: "GET",
        url: `/api/orders/${_order.name}`
      });
      fulfillmentId = orderData.data.orders[_order.id].fulfillmentId.replace("gid://shopify/Fulfillment/", "");
      _order.fulfillmentId = orderData.data.orders[_order.id].fulfillmentId.replace("gid://shopify/Fulfillment/", "");
    }

    await axios.request({
      method: "POST",
      url: `/api/order/fulfill/cancel/${fulfillmentId}`
    }).then(async (r) => {
      if (r.status === 200) {
        const orderName = r.data.data.fulfillmentCancel.fulfillment.order.name;
        // Pull order (only this order) again using API (why? to get the new fulfillmentOrderId, and to be able to fulfill it again)
        await axios.request({
          method: "GET",
          url: `/api/orders/${orderName}`
        }).then((r) => {
          if (r.status === 200) {
            // Update order for everybody
            axios.post(`/api/event/emit`, {
              event: "UPDATED_ORDER",
              data: JSON.stringify(r.data.orders[_order.id])
            });
          }
        }).catch((error) => {
          console.log(error);
          toast(`Code: FE001`, {type: "error"});
        });
      }
    }).catch((error) => {
      console.log(error);
      toast(`Code: FE002`, {type: "error"});
    });

  }

  let newOrders = null;
  let inProgressOrders = null;
  let doneOrders = null;
  if (orders) {
    newOrders = Object.keys(orders).filter((o => orders[o].status === ""));
    inProgressOrders = Object.keys(orders).filter((o => {
      if (viewAllOrders) {
        return orders[o].status === "PICK";
      }
      return orders[o].status === "PICK" && orders[o].agent === profile.email;
    }));
    doneOrders = Object.keys(orders).filter((o => orders[o].status === "FULFILL"));

    doneOrders = doneOrders.map(o => orders[o]).sort((a, b) => {
      const dateA = new Date(a.createdAt);
      const dateB = new Date(b.createdAt);
      return dateB - dateA;
    });
  }

  return (
    <div>
      <ToastContainer />
      {orders ? (
        <div className="orders_container">
          <Column width="28">
            <Title
              color="#FFF"
              bgColor="#5B89FF"
              nbOrders={newOrders.length}
            >
              NEW
            </Title>
            <div className="orders_container_content">
              {newOrders.map((o) => {
                return (
                  <OrderCard username={name} order={orders[o]} handleNextStatus={handleNextStatus} handlePickedItem={handlePickedItem} />
                );
              })}
            </div>
          </Column>
          <Column width="46">
            <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
              <Title
                color="#000"
                bgColor="#FEFF93"
                nbOrders={inProgressOrders.length}
              >
                IN PROGRESS
              </Title>
              <div>
                <button className={`view_button ${!viewAllOrders ? "view_button--selected" : ""}`} onClick={() => setViewAllOrders(false)}>MY ORDERS</button>
                <button className={`view_button ${viewAllOrders ? "view_button--selected" : ""}`} onClick={() => setViewAllOrders(true)}>ALL ORDERS</button>
              </div>
            </div>
            <div className="orders_container_content">
              {inProgressOrders.map((o) => {
                return (
                  <OrderCard email={profile.email} order={orders[o]} handleNextStatus={handleNextStatus} handlePickedItem={handlePickedItem} />
                );
              })}
            </div>
          </Column>
          <Column width="28">
            <Title
              color="#000"
              bgColor="#1ADF1A"
              nbOrders={doneOrders.length}
            >
              DONE
            </Title>
            <div className="orders_container_content">
              {doneOrders.map((o) => {
                return (
                  <OrderCard order={o} handleNextStatus={handleNextStatus} handlePickedItem={handlePickedItem} handleCancelFulfillment={handleCancelFulfillment} />
                );
              })}
            </div>
          </Column>
        </div>
      ) : (
        <p>Loading orders...</p>
      )}
    </div>
  )

}

export default OrderFulfillment;