import "./title.css";

function Title({ children, color = "#000", bgColor = "#FFF", nbOrders = 0 }) {
  return (
    <div className="title">
      {children}
      <div className="title_tag" style={{ color: color, backgroundColor: bgColor}}>{nbOrders} orders</div>
    </div>
  )

}

export default Title;